import React from 'react'

const NotFound = () => {
  return (
    <div className='flex place-content-center place-items-center w-full h-screen'>
      <p className='text-3xl font-semibold text-slate-400'>Not Found</p>
    </div>
  )
}

export default NotFound
