import React, { useEffect } from "react";
import Title from "../../../component/title";
import { useState } from "react";
import { BiQuestionMark } from "react-icons/bi";
import axios from "axios";
import Cookies from "js-cookie";
import { useStateContext } from "../../../utils/contextprovider";
import { Button, MenuItem, Select, Tooltip } from "@mui/material";

const Profile = () => {
  const [orgName, setOrgName] = useState("");
  const [industries, setIndustries] = useState([]);
  const [industry, setIndustry] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [baseCurrency, setBaseCurrency] = useState("");
  const [fiscalRange, setFiscalRange] = useState("");
  const [startDate, setStartDate] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const apiUrl = process.env.REACT_APP_INVENTORY_API_URL;
  const apiKey = process.env.REACT_APP_INVENTORY_API_KEY;
  const token = Cookies.get("inventory_app_token");
  const { user } = useStateContext();

  const params = {
    org_id: user.org_id,
  };

  useEffect(() => {
    getDetails();
    getIndustries();
  }, []);

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#6E6658 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#2E4053",
      textTransform: "none",
      color: "#fff",
    },
  };

  const getIndustries = () => {
    axios
      .get(apiUrl + "organisation/industry", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        console.log(results.data.data);
        //console.log([...results.data])
        setIndustries(results.data.data);
      })
      .catch((error) => {});
  };

  const getDetails = () => {
    axios
      .get(apiUrl + "organisation/profile", {
        params,
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        console.log(results.data);
        setOrgName(results.data.data.org_name);
        setIndustry(results.data.data.industry_name);
        setOrgAddress(results.data.data.address);
        setEmail(results.data.data.email);
        setPhoneNumber(results.data.data.phone_number)
      })
      .catch((error) => {});
  };

  const handleChangeIndustry = (event) => {
    setIndustry(event.target.value);
  };

  const handleChangeBase = (event) => {
    setBaseCurrency(event.target.value);
  };
  const handleChangeFiscal = (event) => {
    setFiscalRange(event.target.value);
  };
  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  return (
    <div className="flex flex-col w-full h-full">
      <Title title={"Profile"} />
      <div
        className="w-full sm:w-3/4 h-[82vh] flex flex-col p-10 gap-3 overflow-auto"
        style={{ scrollbarWidth: "thin" }}
      >
        <div className="flex place-items-center place-content-between gap-10">
          <p className="text-xs text-red-500">Organization Name*</p>
          <div className="w-2/3">
            <input
              className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
              type="text"
              id="org_id"
              value={orgName}
              onChange={(e) => {
                setOrgName(e.target.value);
              }}
              placeholder=""
            />
          </div>
        </div>
        <div className="flex place-items-center place-content-between gap-10">
          <div className="flex gap-2 place-items-center">
            <p className="text-xs">Industry</p>
            <Tooltip title="Select Your industry type">
              <div className="w-5 h-5 bg-slate-400 flex place-content-center place-items-center rounded-sm">
                <BiQuestionMark color="white" />
              </div>
            </Tooltip>
          </div>
          <div className="w-2/3">
            <Select
              fullWidth
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={industry}
              label=""
              onChange={handleChangeIndustry}
            >
              {industries.map((value, index) => (
                <MenuItem value={value.name} key={index + "industry"}>
                  <p>{value.name}</p>
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div className="flex place-items-center place-content-between gap-10">
          <div className="flex gap-2 place-items-center">
            <p className="text-xs">Organization Address</p>
            <Tooltip title="Enter the location of the organisation">
              <div className="w-5 h-5 bg-slate-400 flex place-content-center place-items-center rounded-sm">
                <BiQuestionMark color="white" />
              </div>
            </Tooltip>
          </div>
          <div className="w-2/3">
            <input
              className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
              type="text"
              id="org_id"
              value={orgAddress}
              onChange={(e) => {
                setOrgAddress(e.target.value);
              }}
              placeholder=""
            />
          </div>
        </div>

        <div className="flex place-items-center place-content-between gap-10">
          <div className="flex gap-2 place-items-center">
            <p className="text-xs">Email</p>
          </div>
          <div className="w-2/3">
            <input
              className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
              type="text"
              id="org_id"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder=""
            />
          </div>
        </div>
        <div className="flex place-items-center place-content-between gap-10">
          <div className="flex gap-2 place-items-center">
            <p className="text-xs">Phone number</p>
          </div>
          <div className="w-2/3">
            <input
              className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
              type="text"
              id="org_id"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              placeholder=""
            />
          </div>
        </div>
       
        <div className="w-full p-3 border-t flex flex-shrink-0 mt-5 gap-3">
          <Button sx={useStyle.Button}>Save</Button>
          <Button>Cancel</Button>{" "}
        </div>
      </div>
    </div>
  );
};

export default Profile;
