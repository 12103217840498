import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import Chart from "react-google-charts";
import { BiPackage } from "react-icons/bi";
import { CiCircleCheck, CiImageOn } from "react-icons/ci";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { MdOutlineLocalShipping } from "react-icons/md";

const DashboardTab = () => {
  const [topSellingMenu, setTopSellingMenu] = useState("This Month");
  const [purchaseOrderMenu, setPurchaseOrderMenu] = useState("This Month");
  const [salesOrderMenu, setSalesOrderMenu] = useState("This Month");
  const [salesOrderSummaryMenu, setSalesOrderSummaryMenu] =
    useState("This Month");

  /// Update the top selling filter value
  const handleChangeTopSellingMenu = (e) => {
    setTopSellingMenu(e.target.value);
  };

  const handleChangePurchaseOrderMenu = (e) => {
    setPurchaseOrderMenu(e.target.value);
  };
  const handleChangeSalesOrderMenu = (e) => {
    setSalesOrderMenu(e.target.value);
  };
  const handleChangeSalesOrderSummaryMenu = (e) => {
    setSalesOrderSummaryMenu(e.target.value);
  };

  const data = [
    ["", "Percentage"],
    ["Active Items", 75],
    ["", 25],
  ];

  const options = {
    title: "Active Items",
    legend: "none",
    pieHole: 0.4,
    pieStartAngle: 0,
    slices: {
      0: { color: "#4ECAAE" },
      1: { color: "#EFEFEF" },
    },
  };
  return (
    <div
      className="w-full  h-[70vh] overflow-auto flex flex-col p-5 gap-5"
      style={{ scrollbarWidth: "thin" }}
    >
      <div className="w-full  flex sm:flex-row flex-col gap-5 flex-shrink-0">
        <div className="h-60 w-full sm:w-2/3 border border-slate-300 rounded-lg flex flex-col">
          <div className="w-full h-16 flex place-items-center p-3 border-b border-slate-300 bg-zinc-50 rounded-t-lg">
            <p className="text-black text-sm sm:text-xl">Sales Activity</p>
          </div>
          <div className="w-full h-full flex place-content-between p-2 sm:p-5">
            <div className="w-1/4 h-full flex place-content-center place-items-center flex-col gap-4 border-r">
              <div className="flex flex-col gap-2 place-items-center">
                <p className="text-2xl sm:text-5xl text-blue-500">51</p>
                <p className="text-sm text-slate-400">Qty</p>
              </div>
              <div className="flex gap-2 place-items-center">
                <CiCircleCheck size={18} color="black" />
                <p className="text-xs sm:text-sm text-black font-normal">
                  TO BE PACKED
                </p>
              </div>
            </div>
            <div className="w-1/4 h-full flex place-content-center place-items-center flex-col gap-4 border-r">
              <div className="flex flex-col gap-2 place-items-center">
                <p className="text-2xl sm:text-5xl text-red-500">40</p>
                <p className="text-sm text-slate-400">Pkgs</p>
              </div>
              <div className="flex gap-2 place-items-center">
                <MdOutlineLocalShipping size={18} color="black" />
                <p className="text-xs sm:text-sm text-black font-normal">
                  TO BE SHIPPED
                </p>
              </div>
            </div>
            <div className="w-1/4 h-full flex place-content-center place-items-center flex-col gap-4 border-r">
              <div className="flex flex-col gap-2 place-items-center">
                <p className="text-2xl sm:text-5xl text-green-500">52</p>
                <p className="text-sm text-slate-400">Pkgs</p>
              </div>
              <div className="flex gap-1 place-items-center">
                <BiPackage size={18} color="black" />
                <p className="text-xs sm:text-sm text-black font-normal">
                  TO BE DELIVERED
                </p>
              </div>
            </div>
            <div className="w-1/4 h-full flex place-content-center place-items-center flex-col gap-4">
              <div className="flex flex-col gap-2 place-items-center">
                <p className="text-2xl sm:text-5xl text-yellow-500">97</p>
                <p className="text-sm text-slate-400">Qty</p>
              </div>
              <div className="flex gap-1 place-items-center">
                <LiaFileInvoiceSolid size={18} color="black" />
                <p className="text-xs sm:text-sm text-black font-normal">
                  TO BE INVOICED
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="h-60 w-full sm:w-1/3 border border-slate-300 rounded-lg flex flex-col gap-5 place-items-center">
          <div className="w-full h-14 flex place-items-center p-3 border-b border-slate-300 bg-zinc-50 rounded-t-lg">
            <p className="text-black text-xl">Inventory Summary</p>
          </div>

          <div className="flex place-content-between place-items-center w-[95%] p-5 border-b border-slate-300">
            <p className="text-slate-400 text-base">QUANTITY IN HAND</p>
            <p className="text-2xl text-black">1236</p>
          </div>
          <div className="flex place-content-between place-items-center w-[95%] pt-0 p-5 ">
            <p className="text-slate-400 text-base">QUANTITY TO BE RECEIVED</p>
            <p className="text-2xl text-black">16</p>
          </div>
        </div>
      </div>

      <div className="w-full flex-shrink-0 h-72 flex gap-5">
        <div className="w-1/2 h-full border flex flex-col border-slate-300 rounded-lg">
          <div className="w-full h-14 flex place-items-center p-3 border-b border-slate-300 bg-zinc-50 rounded-t-lg">
            <p className="text-black text-xl">Product Details</p>
          </div>
          <div className="w-full h-full flex ">
            <div className="w-[60%] h-full border-r border-slate-300 flex flex-col p-5 gap-5 place-items-center place-content-center">
              <div className="w-full flex place-content-between text-red-500">
                <p className="text-lg font-normal">Low Stock Items</p>
                <p className="font-semibold text-xl">22</p>
              </div>
              <div className="w-full flex place-content-between">
                <p className="text-lg font-normal text-slate-500">
                  All Items Groups
                </p>
                <p className="font-semibold text-xl text-black">34</p>
              </div>
              <div className="w-full flex place-content-between ">
                <p className="text-lg font-normal text-slate-500">
                  Low Stock Items
                </p>
                <p className="font-semibold text-xl text-black">22</p>
              </div>
            </div>
            <div className="w-[40%] h-full flex place-content-center place-items-center">
              <Chart
                chartType="PieChart"
                width="100%"
                height="100%"
                data={data}
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full border border-slate-300 rounded-lg">
          <div className="w-full h-14 flex place-items-center place-content-between p-3 border-b border-slate-300 bg-zinc-50 rounded-t-lg">
            <p className="text-black text-xl">Top Selling Items</p>
            <div className="h-8 w-32">
              <FormControl size="small" fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={topSellingMenu}
                  label=""
                  onChange={handleChangeTopSellingMenu}
                  size="small"
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      { border: 0 },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      { border: 0 },
                  }}
                >
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Yesterday">Yesterday</MenuItem>
                  <MenuItem value="This Week">This Week</MenuItem>
                  <MenuItem value="This Month">This Month</MenuItem>
                  <MenuItem value="This Year">This Year</MenuItem>
                  <MenuItem value="Previous Week">Previous Week</MenuItem>
                  <MenuItem value="Previous Month">Previous Month</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="flex w-full h-[32vh] place-content-center place-items-center gap-1">
            <div className=" w-1/3  border-r border-zinc-300 flex flex-col h-full  place-items-center place-content-center p-5 gap-5">
              <CiImageOn size={60} />
              <div className="flex flex-col gap-3">
                <p className="text-sm">Area Rug</p>
                <div className="flex gap-1 place-items-end">
                  <p className="text-2xl text-black">135</p>
                  <p className="text-sm text-zinc-400 mb-1">Ltr</p>
                </div>
              </div>
            </div>
            <div className=" w-1/3  border-r border-zinc-300 flex flex-col h-full  place-items-center place-content-center p-5 gap-5">
              <CiImageOn size={60} />
              <div className="flex flex-col gap-3">
                <p className="text-sm">Area Rug</p>
                <div className="flex gap-1 place-items-end">
                  <p className="text-2xl text-black">135</p>
                  <p className="text-sm text-zinc-400 mb-1">Ltr</p>
                </div>
              </div>
            </div>
            <div className=" w-1/3 flex flex-col h-full place-items-center place-content-center p-5 gap-5">
              <CiImageOn size={60} />
              <div className="flex flex-col gap-3">
                <p className="text-sm">Area Rug</p>
                <div className="flex gap-1 place-items-end">
                  <p className="text-2xl text-black">135</p>
                  <p className="text-sm text-zinc-400 mb-1">Ltr</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-shrink-0 h-72 gap-5 ">
        <div className="w-1/3 h-full rounded-lg  border border-slate-300">
          <div className="w-full h-14 flex place-items-center place-content-between p-3 border-b border-slate-300 bg-zinc-50 rounded-t-lg">
            <p className="text-black text-xl">Purchase Order</p>
            <div className="h-8 w-32">
              <FormControl size="small" fullWidth>
                <Select
                  labelId="purchase-order"
                  id="purchase-order-select"
                  value={purchaseOrderMenu}
                  label=""
                  onChange={handleChangePurchaseOrderMenu}
                  size="small"
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      { border: 0 },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      { border: 0 },
                  }}
                >
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Yesterday">Yesterday</MenuItem>
                  <MenuItem value="This Week">This Week</MenuItem>
                  <MenuItem value="This Month">This Month</MenuItem>
                  <MenuItem value="This Year">This Year</MenuItem>
                  <MenuItem value="Previous Week">Previous Week</MenuItem>
                  <MenuItem value="Previous Month">Previous Month</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="w-full h-[30vh] flex flex-col place-items-center place-content-center p-3">
            <div className="w-full flex flex-col place-content-center place-items-center gap-3 pb-5 border-b border-slate-300">
              <p className="text-xs text-black">Quantity Ordered</p>
              <p className="text-3xl text-blue-500">2.00</p>
            </div>
            <div className="w-full flex flex-col place-content-center place-items-center gap-3 pb-5 pt-5">
              <p className="text-xs text-black">Total Cost</p>
              <p className="text-3xl text-blue-500">$14500.00</p>
            </div>
          </div>
        </div>
        <div className="w-2/3 h-full rounded-lg  border border-slate-300">
          <div className="w-full h-14 flex  place-items-center place-content-between p-3 border-b border-slate-300 bg-zinc-50 rounded-t-lg">
            <p className="text-black text-xl">Sales Order</p>
            <div className="h-8 w-32">
              <FormControl size="small" fullWidth>
                <Select
                  labelId="sales-order"
                  id="sales-order-select"
                  value={salesOrderMenu}
                  label=""
                  onChange={handleChangeSalesOrderMenu}
                  size="small"
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      { border: 0 },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      { border: 0 },
                  }}
                >
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Yesterday">Yesterday</MenuItem>
                  <MenuItem value="This Week">This Week</MenuItem>
                  <MenuItem value="This Month">This Month</MenuItem>
                  <MenuItem value="This Year">This Year</MenuItem>
                  <MenuItem value="Previous Week">Previous Week</MenuItem>
                  <MenuItem value="Previous Month">Previous Month</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="w-full h-[30vh]">
            <table className="w-full ">
              <thead className="w-full h-12 text-normal bg-slate-100 text-xs">
                <tr>
                <th>Channel</th>
                <th>Draft</th>
                <th>Confirmed</th>
                <th>Packaged</th>
                <th>Shipped</th>
                <th>Invoiced</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default DashboardTab;
