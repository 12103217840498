import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyDgRcoWUxWBk8zWS-MSiKAYHSS_9IXDk9o",
    authDomain: "inventory-management-d92d8.firebaseapp.com",
    projectId: "inventory-management-d92d8",
    storageBucket: "inventory-management-d92d8.appspot.com",
    messagingSenderId: "203740648091",
    appId: "1:203740648091:web:3bb978a5a0c7b00a7aec3e"
};

const app = initializeApp(firebaseConfig);

export default app;