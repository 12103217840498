import React from "react";
import { IoClose } from "react-icons/io5";

const Notifications = ({ setShowNotifications }) => {
  return (
    <>
      <div className=" bg-white h-full w-full absolute right-0 bottom-0 flex flex-col">
        <div className="w-full h-20 bg-slate-100 flex place-content-between place-items-center px-5 border-b">
          <p className="text-lg font-normal">Notification</p>
          <IoClose
            size={22}
            className="text-red-500 cursor-pointer"
            onClick={() => {
              setShowNotifications(false);
            }}
          />
        </div>

        <div className="flex w-full h-full place-content-center place-items-center">
          <p>There are no Notifications at the moment</p>
        </div>
      </div>
    </>
  );
};

export default Notifications;
