import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { HiMagnifyingGlassPlus } from "react-icons/hi2";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { CiImageOn, CiSettings } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { GoQuestion } from "react-icons/go";
import { IoAddCircle } from "react-icons/io5";
import app from "../../../../utils/app";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import {
  MdArrowForwardIos,
  MdClose,
  MdOutlineArrowBackIos,
  MdOutlineInfo,
} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import AddManufacturer from "../../../../component/addmanufacturer";
import { useClickAway } from "react-use";
import axios from "axios";
import Cookies from "js-cookie";
import { useStateContext } from "../../../../utils/contextprovider";
import { ToastContainer, toast } from "react-toastify";
import AddBrand from "../../../../component/addbrand";

const NewItem = ({ setNewItem }) => {
  const [images, setImages] = useState([]);
  const [itemName, setItemName] = useState("");
  const [unit, setUnit] = useState("cm");
  const [length, setLength] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [itemSKU, setItemSKU] = useState("");
  const [itemUPC, setItemUPC] = useState("");
  const [itemEAN, setItemEAN] = useState("");
  const [itemUnits, setItemUnits] = useState("");
  const [manufacturer, setManufactures] = useState("");
  const [manufacturers, setManufacturers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [weight, setWeight] = useState("");
  const [baseCurrency, setBaseCurrency] = useState({});
  const [salesDescription, setSalesDescription] = useState("");
  const [purchaseDescription, setPurchasesDescription] = useState("");
  const [itemSalesAccount, setItemSalesAccount] = useState("sales");
  const [itemInventoryAccount, setItemInventoryAccount] = useState("inventory");
  const [itemPurchasesAccount, setItemPurchasesAccount] =
    useState("cost_of_goods_sold");
  const [itemMPN, setItemMPN] = useState("");
  const [preferredVendor, setPreferredVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [itemISBN, setItemISBN] = useState("");
  const [itemSalesTax, setItemSalesTax] = useState("");
  const [itemPurchaseTax, setItemPurchaseTax] = useState("");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [brand, setBrand] = useState("");
  const [itemEligable, setItemEligable] = useState(true);
  const [addManufacturer, setAddManufacturer] = useState(false);
  const [addBrand, setAddBrand] = useState(false);
  const [sellingPrice, setSellingPrice] = useState();
  const [itemOpeningStock, setItemOpeningStock] = useState();
  const [itemReorderPoint, setItemReorderPoint] = useState();
  const [costPrice, setCostPrice] = useState();
  const [open, setOpen] = useState(false);
  const [openBrand, setOpenBrand] = useState(false);
  const [viewImage, setViewImage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const apiUrl = process.env.REACT_APP_INVENTORY_API_URL;
  const apiKey = process.env.REACT_APP_INVENTORY_API_KEY;
  const token = Cookies.get("inventory_app_token");
  const { user } = useStateContext();
  const params = {
    org_id: user.org_id,
  };

  useEffect(() => {
    getItems();
    getManufacturers();
    getBrands();
  }, []);

  const getItems = () => {
    axios
      .get(apiUrl + "items/index", {
        params,
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        console.log(results.data);
        setLoading(false);
        setTaxes(results.data.tax);
        setBaseCurrency(results.data.baseCurrency);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        console.log(error);
      });
  };

  const getBrands = () => {
    axios
      .get(apiUrl + "items/brands", {
        params,
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        console.log("Heeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrreeeeeeee");
        console.log(results.data);
        setBrands(results.data.brands);
      })
      .catch((error) => {});
  };

  const getManufacturers = () => {
    axios
      .get(apiUrl + "items/manufacturers", {
        params,
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        //console.log("Heeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrreeeeeeee");
        console.log(results.data);
        setManufacturers(results.data.manufacturers);
      })
      .catch((error) => {});
  };

  const hiddenFileInput = useRef(null);

  const refManufacture = useRef(null);
  const refBrand = useRef(null);

  useClickAway(refManufacture, () => {
    setAddManufacturer(false);
  });
  useClickAway(refBrand, () => {
    setAddBrand(false);
  });

  const handleChangeItemsMenu = (e) => {
    setItemUnits(e.target.value);
  };

  const handleChangeSalesTaxMenu = (e) => {
    setItemSalesTax(e.target.value);
  };
  const handleChangePurchaseTaxMenu = (e) => {
    setItemPurchaseTax(e.target.value);
  };
  const handleChangeBrandMenu = (e) => {
    setBrand(e.target.value);
  };
  const handleChangeSalesAccountMenu = (e) => {
    setItemSalesAccount(e.target.value);
  };
  const handleChangePreferredVendorMenu = (e) => {
    setPreferredVendor(e.target.value);
  };
  const handleChangePurchasesAccountMenu = (e) => {
    setItemPurchasesAccount(e.target.value);
  };
  const handleChangeInventoryAccountMenu = (e) => {
    setItemInventoryAccount(e.target.value);
  };
  const handleChangeManufacturerMenu = (e) => {
    setManufactures(e.target.value);
  };
  const handleChangeUnitMenu = (e) => {
    setUnit(e.target.value);
  };
  const handleChangeWeightMenu = (e) => {
    setWeightUnit(e.target.value);
  };
  /// handle image selector
  const uploadHandler = () => (event) => {
    var files = event.target.files;
    var arr = [];

    for (let i = 0; i < files.length; i++) {
      const obj = URL.createObjectURL(files[i]);
      const file = files[i];
      arr.push({ name: files.item(i).name, item: obj, file: file });
    }
    event.target.value = null;

    console.log(arr);

    setImages([...images, ...arr]);
  };

  // on Click of select images
  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseBrand = () => {
    setOpenBrand(false);
  };

  const handleOpenBrand = () => {
    setOpenBrand(true);
  };
  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#6E6658 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#2E4053",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };

  const framerMenuPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const uploadProduct = (imgs) => {
    let product = {
      org_id: user.org_id,
      images: imgs,
      name: itemName,
      sku: itemSKU,
      unit: itemUnits,
      returnable: itemEligable,
      dimensions: {
        clength: length,
        cwidth: width,
        cheight: height,
      },
      weight: weight === "" ? 0 : weight,
      manufacturer: manufacturer,
      brand: brand,
      upc: itemUPC,
      mpn: itemMPN,
      ean: itemEAN,
      isbn: itemISBN,
      salesInfo: {
        price: sellingPrice,
        description: salesDescription,
        tax: itemSalesTax,
      },
      purchaseInfo: {
        price: costPrice,
        description: purchaseDescription,
        tax: itemPurchaseTax,
        vendor: preferredVendor === "" ? 0 : preferredVendor,
      },
      inventory: {
        opening: itemOpeningStock,
        reorder: itemReorderPoint,
      },
    };

    axios
      .post(apiUrl + "items/create_product", product, {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        setUploading(false);
        toast("Product Created");
      })
      .catch((error) => {
        setUploading(false);
        toast("Failed please try again");
      });
  };

  const uploadWithImages = () => {
    var promises = [];
    const urls = [];
    const storage = getStorage(app);
    images.forEach(async (image, index) => {
      const ext = image.name.split(".")[1];
      const dt = Date.now();
      var storageref = ref(storage, "products/" + dt + "_" + index + "." + ext);
      const uploadTask = uploadBytesResumable(storageref, image.file);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
          setUploading(false);
          toast("Failed try again");
          return;
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadUrl) => {
              return downloadUrl;
            }
          );
          console.log("here " + url);
          urls.push(url);
        }
      );
    });
    Promise.all(promises)
      .then((uploadTaskSnapshotsArray) => {
        const promises = [];
        uploadTaskSnapshotsArray.forEach(async (uploadTaskSnap) => {
          promises.push(
            getDownloadURL(uploadTaskSnap.ref).then((downloadUrl) => {
              return downloadUrl;
            })
          );
        });
        return Promise.all(promises);
      })
      .then(async (urlsArray) => {
        uploadProduct(urlsArray);
      });
  };

  return (
    <>
      {loading ? (
        <div className="w-full h-full bg-white flex place-items-center place-content-center">
          <CircularProgress size={16} />
        </div>
      ) : error ? (
        <div className="w-full h-full flex place-content-center place-items-center bg-white">
          <p
            className="text-xs"
            onClick={() => {
              setError(false);
              setLoading(true);
              getItems();
            }}
          >
            Error loading tap to reload
          </p>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col bg-white relative">
          <div className="w-full border-b h-16 flex place-content-between place-items-center pl-10 p-5">
            <p className="text-lg font-normal">New Product</p>
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  setNewItem(false);
                }}
              >
                <MdClose size={18} />
              </IconButton>
            </Tooltip>
          </div>

          <div
            className=" w-full h-[81vh] overflow-auto relative pb-24"
            style={{ scrollbarWidth: "thin" }}
          >
            <div className="w-full sm:h-[65%] bg-slate-50 flex sm:flex-row flex-col p-5 sm:pr-40">
              <div className="w-full sm:w-1/2 h-full sm:p-10 pl-1 pt-3 flex flex-col gap-3">
                <div className="flex place-content-between place-items-center w-full">
                  <div className="w-1/4  h-10 flex place-items-center">
                    <p className="text-red-500 text-xs">Product Name*</p>
                  </div>
                  <div className="w-3/4">
                    <input
                      className="border-[1px] bg-slate-50 rounded-md border-slate-300 text-black w-full p-2"
                      type="text"
                      id="org_id"
                      value={itemName}
                      onChange={(e) => {
                        setItemName(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-black text-xs">Product SKU</p>

                    <Tooltip title="The Stock Keeping Unit">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <input
                      className="border-[1px] bg-slate-50 rounded-md border-slate-300 text-black w-full p-2"
                      type="text"
                      id="org_id"
                      value={itemSKU}
                      onChange={(e) => {
                        setItemSKU(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-red-500 text-xs">Unit*</p>

                    <Tooltip title="The Item will be measured in term of">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <Select
                      size="small"
                      fullWidth
                      id="purchase-order-select"
                      value={itemUnits}
                      onChange={handleChangeItemsMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: "#9E9E9E", fontSize: "15px" }}>
                          Select unit type
                        </em>
                      </MenuItem>
                      <MenuItem value="Units">Units</MenuItem>
                      <MenuItem value="Grams">Grams</MenuItem>
                      <MenuItem value="Kilograms">Kilograms</MenuItem>
                      <MenuItem value="Boxes">Boxes</MenuItem>
                      <MenuItem value="Metres">Metres</MenuItem>
                      <MenuItem value="Pcs">Pcs</MenuItem>
                      <MenuItem value="Tablets">Tablets</MenuItem>
                      <MenuItem value="Litres">Litres</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="pl-36">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={itemEligable}
                        onChange={(e) => {
                          setItemEligable(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <div className="flex gap-1">
                        <p className="text-sm">Returnable Item</p>
                        <Tooltip title="Enable this option if the item is eligible for a return">
                          <div>
                            <GoQuestion />
                          </div>
                        </Tooltip>
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="w-full sm:w-1/2 h-full flex place-content-center place-items-center">
                {images.length === 0 ? (
                  <div
                    className="w-56 h-56 border rounded-lg border-dashed border-zinc-400 bg-white hover:border-blue-500 cursor-pointer flex flex-col place-content-center place-items-center gap-2 text-center p-3"
                    onClick={handleClick}
                  >
                    <CiImageOn size={35} />
                    <p className="text-sm">Select image(s)</p>
                    <p className="text-xs text-slate-500">
                      You can add up to 5 images, each not exceeding 5 MB.
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col gap-3">
                    <div className="w-56 h-56 border border-slate-500  rounded-lg flex flex-col">
                      <div className="h-44 w-full p-2 flex flex-shrink-0 place-content-center place-items-center relative group">
                        <div
                          className="bg-center bg-contain bg-no-repeat w-full h-full"
                          style={{
                            backgroundImage:
                              "url(" + images[currentIndex].item + ")",
                          }}
                        />
                        <div className="w-full h-full absolute p-1">
                          <div
                            className="w-full h-full group-hover:flex hidden place-content-center place-items-center bg-slate-900/50 rounded-md cursor-pointer"
                            onClick={() => {
                              setViewImage(true);
                            }}
                          >
                            <HiMagnifyingGlassPlus size={50} color="white" />
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-full flex place-content-between place-items-center p-3">
                        {currentIndex === 0 ? (
                          <div className="flex place-items-center gap-1">
                            <div className="flex gap-1 place-content-center place-items-center p-1 px-2 bg-blue-300/50 rounded-md h-7">
                              <FaCheckCircle color="green" />
                              <p className="text-xs">Primary</p>
                            </div>
                            <Tooltip title="This Image will be displayed on all transactions">
                              <div>
                                <MdOutlineInfo className="cursor-pointer" />
                              </div>
                            </Tooltip>
                          </div>
                        ) : (
                          <p
                            className="text-blue-600 cursor-pointer text-xs"
                            onClick={() => {
                              let imgs = [...images];
                              imgs.unshift(imgs[currentIndex]);
                              imgs = [
                                ...imgs.slice(0, currentIndex + 1),
                                ...imgs.slice(currentIndex + 2),
                              ];
                              setImages(imgs);
                            }}
                          >
                            Mark as primary
                          </p>
                        )}

                        <IconButton
                          size="small"
                          onClick={() => {
                            setCurrentIndex(0);
                            setImages([
                              ...images.slice(0, currentIndex),
                              ...images.slice(currentIndex + 1),
                            ]);
                          }}
                        >
                          <AiOutlineDelete />
                        </IconButton>
                      </div>
                    </div>
                    <div className="w-56 h-12 flex gap-1 place-items-center">
                      {images.map((value, index) => (
                        <div
                          key={index + "image"}
                          className={
                            index === currentIndex
                              ? "w-10 h-10  border border-blue-500 rounded-md cursor-pointer flex place-content-center place-items-center p-2"
                              : "w-10 h-10  border border-slate-300 rounded-md cursor-pointer flex place-content-center place-items-center p-2"
                          }
                          onClick={() => {
                            setCurrentIndex(index);
                          }}
                        >
                          <div
                            className="bg-center bg-contain bg-no-repeat w-full h-full"
                            style={{
                              backgroundImage: "url(" + value.item + ")",
                            }}
                          />
                        </div>
                      ))}
                      {images.length < 5 && (
                        <div
                          className="w-10 h-10  border border-slate-400 rounded-sm flex place-content-center place-items-center border-dashed cursor-pointer"
                          onClick={handleClick}
                        >
                          <IoAddCircle size={25} color="blue" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <input
                hidden
                accept="image/*"
                type="file"
                ref={hiddenFileInput}
                onChange={uploadHandler()}
                multiple
              />
            </div>
            <div className="w-full pb-10 bg-white border-b border-slate-300 flex sm:flex-row flex-col sm:pr-40">
              <div className="sm:w-1/2 w-full h-full flex flex-col gap-6 p-5">
                <div className="flex w-full place-content-between">
                  <div className="w-1/4  h-10 flex flex-col">
                    <p className="text-sm text-black">Dimensions</p>
                    <p className="text-xs text-slate-400">
                      (Length X Width X Height)
                    </p>
                  </div>
                  <div className="w-3/4  flex rounded-md border border-slate-300">
                    <div className="w-full flex place-items-center">
                      <div className="w-1/3 h-full p-1 flex place-content-center place-items-center">
                        <input
                          className=" bg-transparent text-black w-full h-full text-center focus:outline-none"
                          type="number"
                          id="length"
                          value={length}
                          onChange={(e) => {
                            setLength(e.target.value);
                          }}
                          placeholder=""
                        />
                      </div>
                      <p className="text-xs text-slate-300">X</p>
                      <div className="w-1/3 h-full p-1 flex place-content-center place-items-center">
                        <input
                          className=" bg-transparent text-black w-full h-full text-center focus:outline-none"
                          type="number"
                          id="width"
                          value={width}
                          onChange={(e) => {
                            setWidth(e.target.value);
                          }}
                          placeholder=""
                        />
                      </div>
                      <p className="text-xs text-slate-300">X</p>
                      <div className="w-1/3 h-full p-1 flex place-content-center place-items-center">
                        <input
                          className=" bg-transparent text-black w-full h-full text-center focus:outline-none"
                          type="number"
                          id="height"
                          value={height}
                          onChange={(e) => {
                            setHeight(e.target.value);
                          }}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="w-[20%] h-full border-l border-slate-300 flex place-content-center place-items-center">
                      <Select
                        size="small"
                        fullWidth
                        id="unit-order-select"
                        value={unit}
                        onChange={handleChangeUnitMenu}
                        sx={{
                          fontSize: "14px",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#9E9E9E", fontSize: "15px" }}>
                            Select unit
                          </em>
                        </MenuItem>
                        <MenuItem value="cm">cm</MenuItem>
                        <MenuItem value="mm">mm</MenuItem>
                        <MenuItem value="m">m</MenuItem>
                        <MenuItem value="in">in</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="w-full flex place-content-between place-items-center">
                  <div className="w-1/4  h-10 flex place-items-center">
                    <p className="text-sm text-black">Manufacturer</p>
                  </div>
                  <div className="w-3/4">
                    <Select
                      open={open}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      size="small"
                      fullWidth
                      id="purchase-order-select"
                      value={manufacturer}
                      onChange={handleChangeManufacturerMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: "#9E9E9E", fontSize: "15px" }}>
                          Select or Add Manufacturer
                        </em>
                      </MenuItem>
                      {manufacturers.length === 0 ? (
                        <div className="p-3">
                          <MenuItem disabled value="no result">
                            No RESULTS FOUND
                          </MenuItem>
                        </div>
                      ) : (
                        manufacturers.map((value, index) => (
                          <MenuItem
                            key={"manu" + index}
                            value={value.manufacturer_name}
                          >
                            {value.manufacturer_name}
                          </MenuItem>
                        ))
                      )}

                      <div className="w-full border-t pl-2 pt-2 border-slate-300">
                        <Button
                          sx={{ textTransform: "none" }}
                          startIcon={<CiSettings />}
                          onClick={() => {
                            setAddManufacturer(true);
                            setOpen(false);
                          }}
                        >
                          Add Manufacturer
                        </Button>
                      </div>
                    </Select>
                  </div>
                </div>
                <div className="flex place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-black text-xs">UPC</p>

                    <Tooltip title="12 Digit unique number (Universal product code)">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <input
                      className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
                      type="text"
                      id="org_id"
                      value={itemUPC}
                      onChange={(e) => {
                        setItemUPC(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-black text-xs">EAN</p>

                    <Tooltip title="13 Digit unique number (International Article Number)">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <input
                      className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
                      type="text"
                      id="org_id_ean"
                      value={itemEAN}
                      onChange={(e) => {
                        setItemEAN(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-6 p-5 w-full sm:w-1/2 h-full">
                <div className="flex w-full place-items-center">
                  <div className="w-1/4">
                    <p className="text-sm text-black">Weight</p>
                  </div>

                  <div className="w-3/4  flex rounded-md border border-slate-300">
                    <div className="w-full flex place-items-center">
                      <div className="w-full h-full p-1 flex place-content-center place-items-center">
                        <input
                          className=" bg-transparent text-black w-full h-full text-center focus:outline-none"
                          type="number"
                          id="length"
                          value={weight}
                          onChange={(e) => {
                            setWeight(e.target.value);
                          }}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="w-[20%] h-full border-l border-slate-300 flex place-content-center place-items-center">
                      <Select
                        size="small"
                        fullWidth
                        id="unit-order-select"
                        value={weightUnit}
                        onChange={handleChangeWeightMenu}
                        sx={{
                          fontSize: "14px",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#9E9E9E", fontSize: "15px" }}>
                            Select unit
                          </em>
                        </MenuItem>
                        <MenuItem value="kg">kg</MenuItem>
                        <MenuItem value="g">g</MenuItem>
                        <MenuItem value="lb">lb</MenuItem>
                        <MenuItem value="oz">oz</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="w-full flex place-content-between place-items-center">
                  <div className="w-1/4  h-10 flex place-items-center">
                    <p className="text-sm text-black">Brand</p>
                  </div>
                  <div className="w-3/4">
                    <Select
                      open={openBrand}
                      onClose={handleCloseBrand}
                      onOpen={handleOpenBrand}
                      size="small"
                      fullWidth
                      id="purchase-order-select"
                      value={brand}
                      onChange={handleChangeBrandMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: "#9E9E9E", fontSize: "15px" }}>
                          Select or Add Brand
                        </em>
                      </MenuItem>
                      {brands.length === 0 ? (
                        <div className="p-3">
                          <MenuItem disabled value="no result">
                            No RESULTS FOUND
                          </MenuItem>
                        </div>
                      ) : (
                        brands.map((value, index) => (
                          <MenuItem
                            key={"manu" + index}
                            value={value.brand_name}
                          >
                            {value.brand_name}
                          </MenuItem>
                        ))
                      )}

                      <div className="w-full border-t pl-2 pt-2 border-slate-300">
                        <Button
                          sx={{ textTransform: "none" }}
                          startIcon={<CiSettings />}
                          onClick={() => {
                            setOpenBrand(false);
                            setAddBrand(true);
                          }}
                        >
                          Add Brands
                        </Button>
                      </div>
                    </Select>
                  </div>
                </div>
                <div className="flex place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-black text-xs">MPN</p>

                    <Tooltip title="Manufacturing part number unambigiously identifies a part design">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <input
                      className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
                      type="text"
                      id="org_id_ean"
                      value={itemMPN}
                      onChange={(e) => {
                        setItemMPN(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-black text-xs">ISBN</p>

                    <Tooltip title="13 digit unique commercial book identifier (International standard book number)">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <input
                      className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
                      type="text"
                      id="org_id_ean"
                      value={itemISBN}
                      onChange={(e) => {
                        setItemISBN(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full sm:pr-40 flex sm:flex-row flex-col">
              <div className="w-1/2 h-full flex flex-col p-6 gap-3 cursor-text">
                <p className="text-md mt-1">Sales Information</p>
                <div className="flex place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-red-500 text-xs">Selling Price*</p>

                    <Tooltip title="The rate at which the item will be sold">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4 flex place-items-center">
                    <div className="rounded-l-md border border-r-0 border-slate-300 h-[41.5px] w-16 flex place-content-center place-items-center bg-zinc-50">
                      <p className="text-sm">{baseCurrency.symbol}</p>
                    </div>
                    <input
                      className="border-[1px] bg-transparent rounded-r-md border-slate-300 text-black w-full p-2"
                      type="number"
                      id="org_id_ean"
                      value={sellingPrice}
                      onChange={(e) => {
                        setSellingPrice(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex w-full place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-red-500 text-xs">Account*</p>
                    <Tooltip title="All transactions will be tracked under this account">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <Select
                      size="small"
                      fullWidth
                      id="purchase-order-select"
                      value={itemSalesAccount}
                      onChange={handleChangeSalesAccountMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="sales">
                        <p className="text-sm">Sales</p>
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="flex place-content-between place-items-start w-full">
                  <div className="w-1/4  h-10 flex place-items-center">
                    <p className="text-black text-xs">Description</p>
                  </div>
                  <div className="w-3/4">
                    <textarea
                      className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2 resize-none"
                      type="text"
                      rows={3}
                      id="org_id"
                      value={salesDescription}
                      onChange={(e) => {
                        setSalesDescription(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex w-full place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-xs">Tax</p>
                    <Tooltip title="Select tax that is applicable for the item">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <Select
                      size="small"
                      fullWidth
                      id="tax-select"
                      value={itemSalesTax}
                      onChange={handleChangeSalesTaxMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: "#9E9E9E", fontSize: "15px" }}>
                          Select Tax
                        </em>
                      </MenuItem>
                      {taxes.map((value, index) => (
                        <MenuItem
                          key={"tax" + index}
                          value={value}
                          className=""
                        >
                          <div className="flex gap-1">
                            <p className="text-sm">{value.tax_name}</p>
                            <p className="text-sm text-slate-500">
                              ({value.rate}%)
                            </p>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="w-1/2 h-full flex flex-col p-6 gap-3 cursor-text">
                <p className="text-md mt-1">Purchase Information</p>

                <div className="flex place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-red-500 text-xs">Cost Price*</p>

                    <Tooltip title="The rate at which you purchased the item at">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4 flex place-items-center">
                    <div className="rounded-l-md border border-r-0 border-slate-300 h-[41.5px] w-16 flex place-content-center place-items-center bg-zinc-50">
                      <p className="text-sm">{baseCurrency.symbol}</p>
                    </div>
                    <input
                      className="border-[1px] bg-transparent rounded-r-md border-slate-300 text-black w-full p-2"
                      type="number"
                      id="org_id_ean"
                      value={costPrice}
                      onChange={(e) => {
                        setCostPrice(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex w-full place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-red-500 text-xs">Account*</p>
                    <Tooltip title="All purchase transactions will be tracked under this account">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <Select
                      size="small"
                      fullWidth
                      id="purchase-order-select"
                      value={itemPurchasesAccount}
                      onChange={handleChangePurchasesAccountMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="cost_of_goods_sold">
                        <p className="text-sm">Cost Of Goods Sold</p>
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="flex place-content-between place-items-start w-full">
                  <div className="w-1/4  h-10 flex place-items-center">
                    <p className="text-black text-xs">Description</p>
                  </div>
                  <div className="w-3/4">
                    <textarea
                      className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2 resize-none"
                      type="text"
                      rows={3}
                      id="org_id"
                      value={purchaseDescription}
                      onChange={(e) => {
                        setPurchasesDescription(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex w-full place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-xs">Tax</p>
                    <Tooltip title="Select tax that is applicable for the item">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <Select
                      size="small"
                      fullWidth
                      id="tax-select"
                      value={itemPurchaseTax}
                      onChange={handleChangePurchaseTaxMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: "#9E9E9E", fontSize: "15px" }}>
                          Select Tax
                        </em>
                      </MenuItem>
                      {taxes.map((value, index) => (
                        <MenuItem
                          key={"tax" + index}
                          value={value}
                          className=""
                        >
                          <div className="flex gap-1">
                            <p className="text-sm">{value.tax_name}</p>
                            <p className="text-sm text-slate-500">
                              ({value.rate}%)
                            </p>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="flex w-full place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-xs">Preferred Vendor</p>
                    <Tooltip title="Select vendor to purchase from">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <Select
                      size="small"
                      fullWidth
                      id="tax-select"
                      value={preferredVendor}
                      onChange={handleChangePreferredVendorMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: "#9E9E9E", fontSize: "15px" }}>
                          Select Vendor
                        </em>
                      </MenuItem>
                      {vendors.map((value, index) => (
                        <MenuItem
                          key={"tax" + index}
                          value={value}
                          className=""
                        >
                          <div className="flex gap-1">
                            <p className="text-sm">{value.tax_name}</p>
                            <p className="text-sm text-slate-500">
                              ({value.rate}%)
                            </p>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-96 flex flex-col p-5 gap-3 border-t border-slate-300 sm:pr-40">
              <p className="text-md mt-1">Inventory Information</p>
              <div className="flex flex-col w-1/2 gap-3 p-5">
                <div className="flex w-full place-content-between place-items-center">
                  <div className="flex gap-1 place-items-center">
                    <p className="text-red-500 text-xs">Inventory Account*</p>
                    <Tooltip title="All inventory will be tracked on this account">
                      <div>
                        <GoQuestion />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <Select
                      size="small"
                      fullWidth
                      id="purchase-order-select"
                      value={itemInventoryAccount}
                      onChange={handleChangeInventoryAccountMenu}
                      sx={{
                        fontSize: "18px",
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="inventory">
                        <p className="text-sm">Inventory</p>
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="flex place-content-between place-items-center w-full">
                  <div className="w-1/4  h-10 flex place-items-center">
                    <Tooltip title="The Stock available for sale">
                      <p className="text-red-500 text-xs">Opening Stock*</p>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <input
                      className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
                      type="number"
                      id="org_id"
                      value={itemOpeningStock}
                      onChange={(e) => {
                        setItemOpeningStock(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex place-content-between place-items-center w-full">
                  <div className="w-1/4  h-10 flex place-items-center">
                    <Tooltip title="The point at which a notification to reorder will be sent">
                      <p className="text-xs">Reorder point</p>
                    </Tooltip>
                  </div>
                  <div className="w-3/4">
                    <input
                      className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
                      type="number"
                      id="org_id"
                      value={itemReorderPoint}
                      onChange={(e) => {
                        setItemReorderPoint(e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fixed bottom-0 h-20 shadow-xl border-t border-slate-300 shadow-slate-400 w-full bg-white flex place-items-center p-3">
            <div className="flex gap-3 h-10">
              <Button
                sx={useStyle.Button}
                onClick={() => {
                  if (itemName === "") {
                    toast("Please fill in name");
                  }
                  if (sellingPrice === undefined) {
                    toast("Please fill in selling price");
                  }
                  if (costPrice === undefined) {
                    toast("Please fill in cost price");
                  }
                  if (itemOpeningStock === undefined) {
                    toast("Please fill in Opening stock");
                    return;
                  }
                  setUploading(true);
                  if (images.length === 0) {
                    uploadProduct([]);
                  } else {
                    uploadWithImages();
                  }
                }}
                endIcon={uploading ? <CircularProgress size={10} /> : <></>}
              >
                Save
              </Button>
              <Button
                sx={useStyle.OutlineButton}
                variant="outlined"
                onClick={() => {
                  setNewItem(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>

          <AnimatePresence mode="wait" initial={false}>
            {addBrand && (
              <div className="fixed w-full h-full top-0 left-0 flex place-content-center">
                <motion.div
                  {...framerSidebarBackground}
                  aria-hidden="true"
                  className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
                />
                <motion.div
                  {...framerMenuPanel}
                  className="absolute sm:w-[40%] w-[90%] z-50"
                  ref={refBrand}
                  aria-label="Account"
                >
                  <AddBrand closeMenu={setAddBrand} reloadMenu={getBrands} />
                </motion.div>
              </div>
            )}
          </AnimatePresence>

          <AnimatePresence mode="wait" initial={false}>
            {addManufacturer && (
              <div className="fixed w-full h-full top-0 left-0 flex place-content-center">
                <motion.div
                  {...framerSidebarBackground}
                  aria-hidden="true"
                  className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
                />
                <motion.div
                  {...framerMenuPanel}
                  className="absolute sm:w-[40%] w-[90%] z-50"
                  ref={refManufacture}
                  aria-label="Account"
                >
                  <AddManufacturer
                    closeMenu={setAddManufacturer}
                    reloadManu={getManufacturers}
                  />
                </motion.div>
              </div>
            )}
          </AnimatePresence>

          <AnimatePresence mode="wait" initial={false}>
            {viewImage && (
              <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
                <motion.div
                  {...framerSidebarBackground}
                  aria-hidden="true"
                  className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
                  onClick={() => {
                    setViewImage(false);
                  }}
                />
                <motion.div
                  {...framerMenuPanel}
                  className="absolute w-[40%] h-[80%] z-50"
                  aria-label="Account"
                >
                  <div className="w-full h-full bg-white rounded-md flex flex-col gap-2 p-3">
                    <div className="w-full h-14 flex place-content-end">
                      <div
                        className="w-10 h-10 rounded-full cursor-pointer flex place-content-center place-items-center bg-black"
                        onClick={() => {
                          setViewImage(false);
                        }}
                      >
                        <AiOutlineClose color="white" size={13} />{" "}
                      </div>
                    </div>
                    <div
                      className="w-full h-full bg-center bg-contain bg-no-repeat flex place-items-center"
                      style={{
                        backgroundImage:
                          "url(" + images[currentIndex].item + ")",
                      }}
                    >
                      <div className="w-full flex place-content-between">
                        <div
                          className={
                            currentIndex === 0
                              ? "invisible"
                              : "w-10 h-10 rounded-full cursor-pointer flex place-content-center place-items-center bg-black"
                          }
                          onClick={() => {
                            if (currentIndex !== 0) {
                              setCurrentIndex(currentIndex - 1);
                            }
                          }}
                        >
                          <MdOutlineArrowBackIos color="white" size={13} />{" "}
                        </div>
                        <div
                          className={
                            currentIndex === images.length - 1
                              ? "invisible"
                              : "w-10 h-10 rounded-full cursor-pointer flex place-content-center place-items-center bg-black"
                          }
                          onClick={() => {
                            if (currentIndex !== images.length - 1) {
                              setCurrentIndex(currentIndex + 1);
                            }
                          }}
                        >
                          <MdArrowForwardIos color="white" size={13} />{" "}
                        </div>
                      </div>
                    </div>
                    <div className="h-10 w-full"></div>
                  </div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>
        </div>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        bounce
      />
    </>
  );
};

export default NewItem;
