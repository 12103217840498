import { createContext, useContext, useState, useEffect } from "react";
/// Application state
/// Get and update the user

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  // setting user to null
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if user data exists in localStorage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // Set user from localStorage
    }
  }, []);

  const updateUser = (user) => {
    setUser(user);
    // Update localStorage with user data
    localStorage.setItem("user", JSON.stringify(user));
  };

  const logOutUser = () => {
    setUser(null);
    // Remove user data from localStorage
    localStorage.removeItem("user");
  };

  return (
    <StateContext.Provider
      value={{
        user,
        updateUser,
        logOutUser,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
