import { GoHome, GoOrganization } from "react-icons/go";
import { iconColor, iconSize } from "./constants";
import { MdOutlineInventory2 } from "react-icons/md";
import { BiPurchaseTag } from "react-icons/bi";
import { IoCartOutline } from "react-icons/io5";
import { BsGraphUp } from "react-icons/bs";

const links = [
  {
    title: "Home",
    link: "/dashboard",
    type: "normal",
    icons: <GoHome size={iconSize} color={iconColor} />,
  },
  {
    title: "Inventory",
    type: "more",
    links: [
      {
        title: "Items",
        link: "/inventory/items",
        type: "normal",
      },
    
      {
        title: "Item Groups",
        link: "/inventory/item-groups",
        type: "normal",
      },
      {
        title: "Price Lists",
        link: "/inventory/price-lists",
        type: "normal",
      },
      {
        title: "Adjustments",
        link: "/inventory/inventory-adjustments",
        type: "normal",
      },
    ],
    icons: <MdOutlineInventory2 size={iconSize} color={iconColor} />,
    iconsSelected: <MdOutlineInventory2 size={iconSize} color="#4eaeee" />,
  },
  {
    title: "Sales",
    type: "more",
    links: [
      {
        title: "Customers",
        link: "/sales/customers",
        type: "normal",
      },
      {
        title: "Retainer Invoices",
        link: "/sales/retainer-invoices",
        type: "normal",
      },
      {
        title: "Sales Orders",
        link: "/sales/sales-order",
        type: "normal",
      },
      {
        title: "Packages",
        link: "/sales/packages",
        type: "normal",
      },
      {
        title: "Shipments",
        link: "/sales/shipments",
        type: "normal",
      },
      {
        title: "Invoices",
        link: "/sales/invoices",
        type: "normal",
      },
      {
        title: "Payments Received",
        link: "/sales/payments-received",
        type: "normal",
      },
      {
        title: "Sales Returns",
        link: "/sales/sales-returns",
        type: "normal",
      },
      {
        title: "Credit Notes",
        link: "/sales/credit-notes",
        type: "normal",
      },
    ],
    icons: <IoCartOutline size={iconSize} color={iconColor} />,
    iconsSelected: <IoCartOutline size={iconSize} color="#4eaeee" />,
  },

  {
    title: "Purchases",
    type: "more",
    links: [
      {
        title: "Vendors",
        link: "/purchases/vendors",
        type: "normal",
      },
      {
        title: "Purchase Order",
        link: "/purchases/purchase-order",
        type: "normal",
      },
      {
        title: "Purchase Receives",
        link: "/purchases/purchase-receives",
        type: "normal",
      },
      {
        title: "Bills",
        link: "/purchases/bills",
        type: "normal",
      },
      {
        title: "Payments Made",
        link: "/purchases/payments-made",
        type: "normal",
      },
      {
        title: "Vendor Credits",
        link: "/purchases/vendor-credits",
        type: "normal",
      },
    ],
    icons: <BiPurchaseTag size={iconSize} color={iconColor} />,
    iconsSelected: <BiPurchaseTag size={iconSize} color="#4eaeee" />,
  },
  {
    title: "Organisation",
    type: "more",
    links: [
      {
        title: "Warehouse",
        link: "/organisation/warehouse",
        type: "normal",
      },
      {
        title: "Profile",
        link: "/organisation/profile",
        type: "normal",
      },
      {
        title: "Branding",
        link: "/organisation/branding",
        type: "normal",
      },
      {
        title: "Currencies",
        link: "/organisation/currencies",
        type: "normal",
      },
    ],
    icons: <GoOrganization size={iconSize} color={iconColor} />,
    iconsSelected: <GoOrganization size={iconSize} color="#4eaeee" />,
  },

  {
    title: "Reports",
    link: "/reports",
    type: "normal",
    icons: <BsGraphUp size={iconSize} color={iconColor} />,
  },
];

export default links;
