// Author: Dylan Karimagoko
// dylankarimagoko@gmail.com
// +263 78 623 2255
import React, { useState } from "react";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { useStateContext } from "../../utils/contextprovider";
import { Tab, Tabs } from "@mui/material";
import DashboardTab from "./dashboard/dashboard_tab";
import Announcements from "./dashboard/announcements";
import RecentUpdates from "./dashboard/recent";
import banner from "../../assets/images/banner.png"

const Dashboard = () => {
  const { user } = useStateContext();
  const [value, setValue] = useState(0);

  console.log(user)

  const tabs = [<DashboardTab />, <Announcements />, <RecentUpdates />];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="w-full h-full  flex flex-col">
      <div className="w-full h-40 border-b px-8 pt-8 flex flex-col place-content-between bg-center bg-contain" style={{backgroundImage: "url(" + banner + ")"}}>
        <div className="w-full flex gap-5">
          <div className="border border-slate-500 h-16 w-16 rounded-lg flex place-content-center place-items-center">
            <HiOutlineBuildingOffice2 size={28} className="text-slate-500" />
          </div>
          <div className="flex flex-col gap-1 place-content-center">
            <p className="text-xl text-black font-normal">Hello, {user.name}</p>
            <p className="text-sm text-zinc-500">{user.org_id}</p>
          </div>
        </div>
        <Tabs value={value} onChange={handleChange} aria-label="dashboard tabs">
          <Tab
            label="Dashboard"
            sx={{ textTransform: "none", color: "black" }}
          />
          <Tab
            label="Announcements"
            sx={{ textTransform: "none", color: "black" }}
          />
          <Tab
            label="Recent Update"
            sx={{ textTransform: "none", color: "black" }}
          />
        </Tabs>
      </div>
      <div
        className="w-full h-[70vh]  overflow-auto"
        style={{ scrollbarWidth: "thin" }}
      >
        {tabs[value]}
      </div>
    </div>
  );
};

export default Dashboard;
