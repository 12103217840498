import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import router from "./utils/routes"
import { ContextProvider } from './utils/contextprovider';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <ContextProvider>
   <RouterProvider router={router}/>
   </ContextProvider>
);

