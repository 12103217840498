import Cookies from "js-cookie";
import { React } from "react";

import { Navigate, Outlet } from "react-router-dom";

const GuestLogin = () => {
  //Check if logged in
  const token = Cookies.get("inventory_app_token");

  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default GuestLogin;