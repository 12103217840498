import React from "react";

const Overview = ({ item }) => {
  console.log(item);
  return (
    <div className="flex flex-col w-full h-full p-5">
      <div className="w-full flex">
        <div className="w-1/2 flex flex-col gap-5">
          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">Item type</p>
            <p className="text-sm">Inventory Items</p>
          </div>
          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">SKU</p>
            <p className="text-sm">{item.sku === "" ? "-" : item.sku}</p>
          </div>
          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">Unit</p>
            <p className="text-sm">{item.unit}</p>
          </div>
          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">UPC</p>
            <p className="text-sm">{item.upc === "" ? "-" : item.upc}</p>
          </div>
          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">EAN</p>
            <p className="text-sm">{item.ean === "" ? "-" : item.ean}</p>
          </div>
          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">ISBN</p>
            <p className="text-sm">{item.isbn === "" ? "-" : item.isbn}</p>
          </div>

          <p className="text-lg mt-5">Sales Information</p>

          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">Selling price</p>
            <p className="text-sm">
              ${item.price === "" ? "-" : parseFloat(item.price).toFixed(2)}
            </p>
          </div>
          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">Description</p>
            <p className="text-sm">
              {item.sales_description === "" ? "-" : item.sales_description}
            </p>
          </div>
          <p className="text-lg mt-5">Purchase Information</p>

          <div className="w-[75%] flex place-content-between">
            <p className="text-sm text-slate-500">Selling price</p>
            <p className="text-sm">
              ${item.cost === "" ? "-" : parseFloat(item.cost).toFixed(2)}
            </p>
          </div>
          <div className="w-[75%] flex place-content-between pb-10">
            <p className="text-sm text-slate-500">Description</p>
            <p className="text-sm">
              {item.description === "" ? "-" : item.description}
            </p>
          </div>
        </div>
        <div className="w-full sm:w-1/2 h-full flex place-content-center">
          {item.images.length === 0 ? (
            <div className="w-3/4 border h-3/4 rounded-lg border-dashed flex place-content-center place-items-center border-slate-500">
              <p>No Images</p>{" "}
            </div>
          ) : (
            <div className="flex gap-2 flex-wrap w-2/3">
              {item.images.map((value, index) => (
                <div className="w-32 h-32">
                  <img src={value} alt="" className="w-full h-full" />{" "}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;
