import { Avatar, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { GoDotFill } from "react-icons/go";
import { IoExitOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { useStateContext } from "../../utils/contextprovider";
import Cookies from "js-cookie";

const Account = ({ setShowAccount }) => {
  const { user } = useStateContext();
  return (
    <>
      <div className="w-full bg-white px-5 h-full absolute right-0 bottom-0 flex flex-col shadow-lg">
        <div className="w-full flex flex-col gap-3 border-b border-b-1 border-slate-300">
          <div className="h-5" />
          <div className="w-full  h-12 flex place-items-center place-content-between ">
            <div className="flex gap-3">
              <Avatar variant="rounded" sx={{ width: 42, height: 42 }} />
              <div className="flex flex-col">
                <p className="font-medium text-base">{user.name}</p>
                <p className="text-xs text-slate-4=500">{user.email}</p>
              </div>
            </div>

            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  setShowAccount(false);
                }}
              >
                <MdClose color="red" size={20} />
              </IconButton>
            </Tooltip>
          </div>

          <div className="flex place-items-center gap-2 mb-3">
            <div className="flex text-[12px] gap-1 font-normal">
              <p>User ID</p> : <p>{user.employee_id}</p>
            </div>
            <GoDotFill size={8} />
            <div className="flex text-[12px] gap-1 font-normal">
              <p>Organisation ID</p> : <p>{user.org_id}</p>
            </div>
          </div>
        </div>

        <div className="w-full h-20 border-b border-b-1 border-slate-300 flex place-content-between place-items-center">
          <p className="text-blue-500">My Account</p>
          <div
            className="flex place-items-center gap-2 cursor-pointer"
            onClick={() => {
              Cookies.remove("inventory_app_token");
              window.location.reload();
              //logOutUser(null);
            }}
          >
            <IoExitOutline size={22} className="text-red-500" />{" "}
            <p className="text-red-500">Sign Out</p>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
