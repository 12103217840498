import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { months } from "../../../utils/months";
import { ToastContainer, toast } from "react-toastify";
import Lottie from "lottie-react";
import astro from "../../../assets/json/astro.json";
import Cookies from "js-cookie";

const RecentUpdates = () => {
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const token = Cookies.get('inventory_app_token');
  const apiUrl = process.env.REACT_APP_INVENTORY_API_URL;
  const apiKey = process.env.REACT_APP_INVENTORY_API_KEY;

  useEffect(() => {
    getUpdates();
  }, []);

  const getUpdates = () => {
    axios
      .get(apiUrl + "updates/recent_updates", {
        headers: {
          "API-Key": apiKey,
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((results) => {
        //console.log(new Date(results.data.data[0].date_posted).getDate())
        setLoading(false);
        setUpdates(results.data.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        toast(error.message);
      });
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#7A5EA0 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#367FA9",
      textTransform: "none",
      color: "#fff",
    },
  };

  return (
    <div className="w-full h-[70vh] p-10 bg-0 overflow-auto  flex flex-col">
      {loading ? (
        <div className="w-full h-full flex place-items-center place-content-center">
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="w-full h-full flex flex-col place-content-center place-items-center gap-5">
          <div className="w-48 h-48">
            <Lottie animationData={astro} loop={true} />
          </div>
          <div className="flex flex-col gap-1 place-items-center place-content-center">
            <p className="text-3xl text-black font-semibold">Oops</p>
            <p className="text-xs text-slate-500">
              Error loading try again
            </p>{" "}
          </div>
          <div className="w-[80%] sm:w-[10vw]">
            <Button
              sx={useStyle.Button}
              fullWidth
              onClick={() => {
                setLoading(true);
                setError(false);
                getUpdates();
              }}
            >
              Reload
            </Button>
          </div>
        </div>
      ) : (
        updates.map((value, index) => (
          <div className="sm:w-1/2 w-full flex gap-5" key={index + "update"}>
            <div className="flex flex-col place-items-center">
              <div className="rounded-full w-2 h-2 bg-blue-500" />
              <div className="w-[0.5px] h-full bg-slate-300" />
            </div>{" "}
            <div className="flex flex-col gap-5 pt-5 py-10">
              <p>{`${new Date(value.date_posted).getDate()} ${
                months[new Date(value.date_posted).getUTCMonth()]
              }  ${new Date(value.date_posted)
                .getUTCFullYear()
                .toString()}`}</p>
              <p className="text-2xl"> {value.title}</p>
              <p className="text-xs">{value.description}</p>
            </div>
          </div>
        ))
      )}
      <ToastContainer
        containerId={"errorRecent"}
        toastClassName="text-xs"
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        bounce
      />
    </div>
  );
};

export default RecentUpdates;
