import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiGridAlt } from "react-icons/bi";
import { MdAdd, MdOutlineList, MdOutlinePhoto } from "react-icons/md";
import NewItem from "./items/new_item";
import axios from "axios";
import Cookies from "js-cookie";
import { useStateContext } from "../../../utils/contextprovider";
import ViewItem from "./items/view_item";
import { AiOutlineReload } from "react-icons/ai";

const Items = () => {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const apiUrl = process.env.REACT_APP_INVENTORY_API_URL;
  const apiKey = process.env.REACT_APP_INVENTORY_API_KEY;
  const token = Cookies.get("inventory_app_token");
  const [newItem, setNewItem] = useState(false);
  const [viewProduct, setViewProduct] = useState();
  const [isList, setIsList] = useState(true);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const { user } = useStateContext();

  const handleChangeMenu = (e) => {
    setItemsPerPage(e.target.value);
    getProducts(1, e.target.value);
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#6E6658 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#2E4053",
      textTransform: "none",
      color: "#fff",
    },
  };
  useEffect(() => {
    getProducts(1, 10);
  }, []);
  const handleChange = (event, value) => {
    // console.log(value);
    setPage(value);
    //setLoading(true);
    getProducts(value, itemsPerPage);
  };

  const getProducts = (val, itemsVal) => {
    axios
      .post(
        apiUrl + "items/products",
        {
          org_id: user.org_id,
          items: itemsVal,
          page: val,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((results) => {
        //console.log("Heeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrreeeeeeee");
        setPages(Math.ceil(results.data.total / itemsVal));
        console.log(results.data);
        setProducts(results.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="w-full h-20 border-b border-slate-300 flex place-items-center place-content-between p-5">
        <div className="flex gap-2 place-items-center w-[50%]">
          <div className="w-full">
            <input
              className="border-[1px] bg-transparent rounded-md border-slate-300 text-black w-full p-2"
              type="text"
              id="org_id"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
            />
          </div>
        </div>

        <div className="flex gap-3 place-items-center">
          <div className="w-20">
            <Button
              fullWidth
              startIcon={<MdAdd color="white" />}
              sx={useStyle.Button}
              onClick={() => {
                setNewItem(true);
              }}
            >
              New
            </Button>
          </div>
          <IconButton
            onClick={() => {
              getProducts();
            }}
          >
            <AiOutlineReload />
          </IconButton>
          <div className="w-20 h-10  border border-slate-300 rounded-md flex">
            <div
              className={
                isList
                  ? "w-1/2 h-full flex place-items-center place-content-center border-r border-slate-300 cursor-pointer bg-slate-300"
                  : "w-1/2 h-full flex place-items-center place-content-center border-r border-slate-300 cursor-pointer"
              }
              onClick={() => {
                setIsList(true);
              }}
            >
              <MdOutlineList />
            </div>
            <div
              className={
                !isList
                  ? "w-1/2 h-full bg-slate-300 flex place-items-center place-content-center cursor-pointer"
                  : "w-1/2 h-full flex place-items-center place-content-center cursor-pointer"
              }
              onClick={() => {
                setIsList(false);
              }}
            >
              <BiGridAlt />
            </div>
          </div>
        </div>
      </div>

      {isList ? (
        <div
          className="w-full flex flex-col h-[80vh] overflow-auto gap-3 pb-10"
          style={{ scrollbarWidth: "thin" }}
        >
          <table>
            <tr className="text-xs text-slate-700 border-b h-8 font-light">
              <th>
                <div className="flex place-content-start pl-10">
                  <p className="text-xs">NAME</p>
                </div>
              </th>
              <th>
                <div className="flex place-content-start">
                  <p className="text-xs">SKU</p>
                </div>
              </th>
              <th>
                <div className="flex place-content-start">
                  <p className="text-xs">TYPE</p>
                </div>
              </th>
              <th>
                <div className="flex place-content-start">
                  <p className="text-xs">DESCRIPTION</p>
                </div>
              </th>
              <th>
                <div className="flex place-content-start">
                  <p className="text-xs">RATE</p>
                </div>
              </th>
            </tr>
            {products.map((value, index) => (
              <tr
                key={"product" + index}
                className="border-b h-10 text-xs cursor-pointer hover:bg-slate-100"
                onClick={() => {
                  setViewProduct(value);
                }}
              >
                <td>
                  <div className="flex gap-2 pl-10 h-12 place-items-center">
                    <div className="w-8 h-8 flex place-content-center place-items-center">
                      {value.images.length === 0 ? (
                        <MdOutlinePhoto size={30} color="grey" />
                      ) : (
                        <img
                          src={value.images[0]}
                          alt=""
                          className="w-full h-full"
                        />
                      )}
                    </div>
                    <p className="text-sm text-blue-500 font-medium">
                      {" "}
                      {value.name}
                    </p>
                  </div>
                </td>
                <td>{value.sku}</td>
                <td>Product</td>
                <td>{value.sales_description}</td>
                <td>
                  {value.price !== null
                    ? "$" + parseFloat(value.price).toFixed(2)
                    : "-"}
                </td>
              </tr>
            ))}
          </table>
          <div className="w-full h-16 flex place-content-between place-items-center px-10">
            <div className="flex gap-2" />
            <div className="w-1/3 flex gap-1 place-items-center place-content-end">
              <div className="w-1/3">
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    sx={{ zIndex: 0 }}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={itemsPerPage}
                    label="Per Page"
                    onChange={handleChangeMenu}
                    size="small"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Pagination page={page} count={pages} onChange={handleChange} />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[80vh]  pb-10 flex flex-col overflow-auto bg-slate-50">
          <div className="w-full flex flex-wrap gap-3 p-3 place-content-center">
            {products.map((value, index) => (
              <div
                key={index + "item grid"}
                className="w-[20%] h-96 bg-white shadow-xl cursor-pointer flex flex-col place-items-center p-5 gap-1"
                onClick={() => {
                  setViewProduct(value);
                }}
              >
                <div className="w-32 h-32 flex place-content-center place-items-center rounded-full border border-slate-200 mb-5">
                  {value.images.length === 0 ? (
                    <MdOutlinePhoto size={30} color="grey" />
                  ) : (
                    <img
                      src={value.images[0]}
                      alt=""
                      className="w-full h-full rounded-full"
                    />
                  )}
                </div>
                <p className="text-md font-medium capitalize">{value.name}</p>
                <div className="w-full flex place-content-center gap-2">
                  <p className="text-zinc-400 text-sm">SKU</p>
                  <p className="text-zinc-400 text-sm">:</p>
                  <p className="text-zinc-400 text-sm">{value.sku === "" ? "-": value.sku}</p>
                </div>
                <div className="w-full flex place-content-center gap-2 mt-5">
                  <p className="text-zinc-400 text-sm">Selling price</p>
                  <p className="text-zinc-400 text-sm">:</p>
                  <p className="text-zinc-400 text-sm">{value.price !== null
                    ? "$" + parseFloat(value.price).toFixed(2)
                    : "-"}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-16 flex place-content-between place-items-center px-10">
            <div className="flex gap-2" />
            <div className="w-1/3 flex gap-1 place-items-center place-content-end">
              <div className="w-1/3">
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    sx={{ zIndex: 0 }}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={itemsPerPage}
                    label="Per Page"
                    onChange={handleChangeMenu}
                    size="small"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Pagination page={page} count={pages} onChange={handleChange} />
            </div>
          </div>
        </div>
      )}

      {newItem && (
        <div className="w-full h-full absolute">
          <NewItem setNewItem={setNewItem} />
        </div>
      )}
      {viewProduct && (
        <div className="w-full h-full absolute">
          <ViewItem viewItem={setViewProduct} item={viewProduct} />
        </div>
      )}
    </div>
  );
};

export default Items;
