// Author David Smart
// divahsmart@gmail.com
// +263779235808

import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { GoOrganization, GoPerson } from "react-icons/go";
import { useStateContext } from "../utils/contextprovider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Button, CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
//import { Navigate } from "react-router-dom";
import back from "../assets/images/login_background.jpg"
import { BsShield } from "react-icons/bs";

const Login = () => {
  const { updateUser, user } = useStateContext();
 // const token = Cookies.get("inventory_app_token");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    org_id: "",
  });
  const { email, password, org_id } = formData;

  const apiUrl = process.env.REACT_APP_INVENTORY_API_URL;
  const apiKey = process.env.REACT_APP_INVENTORY_API_KEY;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  // handling on submit function
  const onSubmit = async (e) => {
    e.preventDefault();

    // Email and password validation logic
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      // If email is not valid
      toast("Please enter a valid email address");
      return;
    }

    if (password.length < 6) {
      // If password is less than 6 characters
      toast("Password must be at least 6 characters long");
      return;
    }
    setLoading(true);
    //console.log(formData);

    axios
      .post(apiUrl + "auth/login", formData, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((result) => {
       
        updateUser(result.data.data);
        /// save token as cookie
        Cookies.set("inventory_app_token", result.data.token, {
          expires: 7,
          secure: true,
        });
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        toast(error.response.data.message);
      });
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#6E6658 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#2E4053",
      textTransform: "none",
      color: "#fff",
    },
  };

  return (
    <div className="flex items-center justify-center w-full h-screen bg-gray-300">
      
        <div className="w-[65%] h-full sm:flex hidden bg-center bg-contain bg-no-repeat" style={{background: "url(" + back + ")"}}/>

     
        <div className="sm:w-[35%] w-[95%] h-full flex place-content-center place-items-center">
        <div className="flex flex-col w-full sm:w-[95%]">
          <div className="text-center">
            <div className="text-2xl text-center  text-[#2E4053] w-full flex flex-col place-content-center place-items-center px-20 ">
              <p className="font-medium text-lg">Welcome to cybastro inventory</p>
              <p className="text-xs font-medium mt-2 leading-tight tracking-tight text-[#2E4053] ">Retail inventory management system. You can track your inventory real time so you know what to restock.</p>
            </div>
            
          </div>
          <div className="p-8 sm:p-12 sm:pt-6">
            <form action="" className="space-y-3" onSubmit={onSubmit}>
              <div className="relative">
                <input
                  className="bg-gray-50 border-[2px] border-[#2E4053] text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 pl-10 pr-12 dark:border-[#2E4053] dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="email"
                  id="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Email address"
                />
                <GoPerson className="absolute top-3 left-3 text-[#2E4053]" />
              </div>
              <div className="relative">
                <input
                  className="bg-gray-50 border-[2px] border-[#2E4053] text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 pl-10 pr-12 dark:border-[#2E4053] dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                  id="org_id"
                  value={org_id}
                  onChange={onChange}
                  placeholder="Organization"
                />
                <GoOrganization className="absolute top-3 left-3 text-[#2E4053]" />
              </div>
              <div className="relative">
                <input
                  className="bg-gray-50 border-[2px] border-[#2E4053] text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 pl-10 pr-12 dark:border-[#2E4053] dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Password"
                />
                {showPassword ? (
                  <AiFillEye
                    className="absolute top-3 right-3 text-gray-500 cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="absolute top-3 right-3 text-gray-500 cursor-pointer"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                )}
                <BsShield className="absolute top-3 left-3 text-[#2E4053]" />
              </div>
              {loading ? (
                <div className="w-full flex place-content-center">
                  <CircularProgress size={18} />
                </div>
              ) : (
                <Button type="submit" sx={useStyle.Button} fullWidth>
                  Sign In
                </Button>
              )}
            </form>
      
        </div></div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        bounce
      />
    </div>
  );
};

export default Login;
