import React from "react";

const Title = ({ title }) => {
  return (
    <div className="w-full h-16 flex place-content-start place-items-center p-3 border-b">
      <p className="text-lg font-normal">{title}</p>
    </div>
  );
};

export default Title;
