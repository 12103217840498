import { Button, CircularProgress, IconButton } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { toast, ToastContainer } from 'react-toastify';
import { useStateContext } from '../utils/contextprovider';

const AddBrand = ({closeMenu, reloadMenu}) => {
    const [newBrand, setNewBrand] = useState(false);
    const [uploading,setUploading] = useState(false);
    const [brandName,setBrandName] = useState("")
    const { user } = useStateContext();
  const apiUrl = process.env.REACT_APP_INVENTORY_API_URL;
  const apiKey = process.env.REACT_APP_INVENTORY_API_KEY;
    const useStyle = {
        Button: {
          "&:hover": {
            backgroundColor: "#6E6658 !important",
            boxShadow: "#ffffff !important",
          },
          backgroundColor: "#2E4053",
          textTransform: "none",
          color: "#fff",
        },
        OutlineButton: {
          textTransform: "none",
          borderColor: "#2E4053",
          color: "#2E4053",
        },
      };

      const saveBrand = () => {
        setUploading(true);

        axios
          .post(
            apiUrl + "items/save_brand",
            {
             brand: brandName,
              org_id: user.org_id,
            },
            {
              headers: {
                "API-Key": apiKey,
              },
            }
          )
          .then((result) => {
            setUploading(false);
            setBrandName("")
            reloadMenu()
            toast("Brand saved");
          })
          .catch((error) => {
            setUploading(false);
            toast(
            "Failed try again"
            );
          });
      }
  return (
    <div className="w-full pb-10 bg-white rounded-b-lg flex flex-col">
        <div className="flex place-content-between place-items-center border-b h-14 p-5">
        <p className="text-sm">Manage Brands</p>
        <IconButton
          onClick={() => {
            closeMenu(false);
          }}
        >
          <AiOutlineClose size={15} color="red" />
        </IconButton>
      </div>
      {!newBrand ? (
        <div className="flex place-items-center p-5 border border-slate-300 border-b">
          <Button
            sx={useStyle.Button}
            startIcon={<AiOutlinePlus size={15} />}
            onClick={() => {
              setNewBrand(true);
            }}
          >
            New Brand
          </Button>
        </div>
      ) : (
        <div className="w-full p-5 flex place-content-center place-items-center">
          <div className="w-full h-40 bg-slate-50 border border-slate-300 flex flex-col place-content-center p-5 gap-3">
            <p className="text-sm text-red-500">Brand Name*</p>
            <input
              className="border-[1px] bg-zinc-50 rounded-md border-slate-300 text-black w-full sm:w-2/3 p-2"
              type="text"
              id="org_id"
              value={brandName}
              onChange={(e) => {
                setBrandName(e.target.value);
              }}
              placeholder=""
            />
            <div className="flex gap-2">
              <Button
                size="small"
                sx={useStyle.Button}
                onClick={() => {
                  if (brandName !== "" || !uploading) {
                    saveBrand();
                  }
                }}
                endIcon={uploading ? <CircularProgress size={10} /> : <></>}
              >
                Save
              </Button>
              <Button
                size="small"
                sx={useStyle.OutlineButton}
                variant="outlined"
                onClick={() => {
                  setNewBrand(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
       <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        bounce
      />
    </div>
  )
}

export default AddBrand