import React, { useRef, useState } from "react";
import { useClickAway } from "react-use";
import { Avatar, IconButton } from "@mui/material";
import { MdMenu } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import { BiBell } from "react-icons/bi";
import Account from "./topbar/account";
import Notifications from "./topbar/notifications";
import { AnimatePresence, motion } from "framer-motion";

const TopBar = ({ toogle, toogleValue }) => {
  const [showAccount, setShowAccount] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const ref = useRef(null);
  const refNotification = useRef(null);
  
  useClickAway(ref, () => {
    setShowAccount(false);
  });
  useClickAway(refNotification, () => {
    setShowNotifications(false);
  });

  const framerSidebarPanel = {
    initial: { x: "100%" },
    animate: { x: 0 },
    exit: { x: "100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };
  return (
    <div className="w-full h-full bg-[#F7F7FE] flex place-content-between place-items-center px-3 ">
      <div className="flex gap-2 place-items-center">
        <div className="sm:hidden">
          <IconButton
            onClick={() => {
              toogle(!toogleValue);
            }}
          >
            <MdMenu />
          </IconButton>
        </div>
      </div>
      <div className="gap-3 flex place-items-center">
        <div
          className="w-10 h-10 flex place-content-center place-items-center cursor-pointer hover:bg-slate-300 rounded-lg"
          onClick={() => {
            setShowNotifications(!showNotifications);
          }}
        >
          <BiBell size={20} />
        </div>
        <div className="w-10 h-10 flex place-content-center place-items-center cursor-pointer hover:bg-slate-300 rounded-lg">
          <FiSettings size={20} />
        </div>

        <Avatar
          variant="rounded"
          sx={{ height: "35px", width: "35px" }}
          className="cursor-pointer"
          onClick={() => {
            setShowAccount(!showAccount);
          }}
        />
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {showAccount && (
          <>
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="absolute bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute w-[30vw] h-full right-0 bottom-0 z-50 "
              ref={ref}
              aria-label="Account"
            >
              <Account setShowAccount={setShowAccount} />
            </motion.div>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
      {showNotifications && (
        <>
        <motion.div
          {...framerSidebarBackground}
          aria-hidden="true"
          className="absolute bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
        />
        <motion.div
          {...framerSidebarPanel}
          className="absolute w-[30vw] h-full right-0 bottom-0 z-50 "
          ref={refNotification}
          aria-label="Account"
        >
          <Notifications setShowNotifications={setShowNotifications} />
        </motion.div>
        </>
      )}
      </AnimatePresence>
    </div>
  );
};

export default TopBar;
