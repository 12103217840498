// Author: Dylan Karimagoko
// dylankarimagoko@gmail.com
// +263 78 623 2255

import React, { Fragment, useEffect, useState } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import TopBar from "../component/topbar";
import links from "../utils/links";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";
import { useStateContext } from "../utils/contextprovider";
/// This page handles main pages
/// if user is null it returns login

const Main = () => {
  const token = Cookies.get("inventory_app_token");
  const { user } = useStateContext();
  /// return user state
  const [toogleMenu, setToogleMenu] = useState(false);
  /// Current page tracker
  const [current, setCurrent] = useState("Home");

  /// check if user or token is null and return login if true
  //console.log(token);
  if (token === null || user === null) {
    return <Navigate to="/login" />;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      toast("Welcome, " + user.name);
    }, []);
  }

  // render link based on if selected
  const selected =
    "flex h-10 w-[95%] text-xs  gap-3 hover:bg-slate-900 cursor-pointer duration-300 place-items-center p-3 bg-[#2980B9] text-white";
  const unselected =
    "flex h-10 w-[95%] gap-3 hover:bg-slate-900 text-xs cursor-pointer duration-300 place-items-center p-3 text-white";

  return (
    <div className="flex h-screen w-full overflow-hidden relative">
      <aside
        className="w-[12.5rem] h-screen bg-[#2E4053] shadow-xl hidden sm:flex flex-col flex-shrink-0 overflow-auto pl-2"
        style={{
          scrollbarWidth: "thin",
        }}
      >
        <div className="h-20" />
        {links.map((value, index) => (
          <Fragment key={index + "item"}>
            {value.type === "more" ? (
              <>
                <Accordion
                  elevation={0}
                  sx={{
                    backgroundColor: "transparent",
                    width: "100%",
                    "&:before": {
                      display: "none",
                    },
                    margin: "0px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <MdOutlineArrowDropDown
                        color={
                          value.links.filter((e) => e.title === current)
                            .length === 0
                            ? "#FFF"
                            : "#4eaeee"
                        }
                      />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      width: "100%",
                      padding: "0px",
                      paddingRight: "10px",
                      margin: "0px",
                    }}
                    className="h-12 "
                  >
                    <div
                      className={
                        value.links.filter((e) => e.title === current)
                          .length === 0
                          ? unselected
                          : "flex h-10 w-[95%]  gap-3 hover:bg-slate-900 cursor-pointer duration-300 place-items-center p-3 text-xs text-[#4eaeee]"
                      }
                    >
                      {value.links.filter((e) => e.title === current).length ===
                      0
                        ? value.icons
                        : value.iconsSelected}
                      <p className="text-sm font-medium">{value.title}</p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ margin: "0px", padding: "0px" }}>
                    <div className=" w-full  ">
                      <div className="w-full flex flex-col">
                        {value.links.map((val, index) => (
                          <div
                            className="flex place-items-center"
                            key={index + "link"}
                          >
                            <div className="w-full">
                              <Link
                                key={index + value.title}
                                to={val.link}
                                onClick={() => {
                                  setCurrent(val.title);
                                }}
                              >
                                <div
                                  className={
                                    current === val.title
                                      ? "flex h-10 w-[95%]  gap-3 hover:bg-slate-900  cursor-pointer duration-300 place-items-center pl-10 p-3 bg-[#367FA9] text-white"
                                      : "flex pl-10 h-10 w-[95%] gap-3 hover:bg-slate-900   cursor-pointer duration-300 place-items-center p-3 text-white"
                                  }
                                >
                                  <p className="text-sm font-medium">
                                    {val.title}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>{" "}
              </>
            ) : (
              <Link
                key={index}
                to={value.link}
                onClick={() => {
                  setCurrent(value.title);
                }}
              >
                <div
                  className={current === value.title ? selected : unselected}
                >
                  {value.icons}
                  <p className="text-sm font-medium">{value.title}</p>
                </div>
              </Link>
            )}{" "}
          </Fragment>
        ))}
      </aside>

      <div
        className={
          toogleMenu
            ? "w-full h-screen absolute top-0 left-0 z-[99999]"
            : "hidden"
        }
        onClick={() => {
          setToogleMenu(!toogleMenu);
        }}
      >
        <aside
          className={
            toogleMenu
              ? "w-[11.5rem] h-screen border-r bg-[#161415] shadow-xl flex sm:hidden flex-col flex-shrink-0 absolute top-0 left-0 z-[9999999999]"
              : "hidden"
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="h-12 w-full flex place-content-end">
            <IconButton
              onClick={() => {
                setToogleMenu(!toogleMenu);
              }}
            >
              <AiOutlineClose color="#FFF" />
            </IconButton>
          </div>
          {links.map((value, index) => (
            <Link key={index} to={value.link} onClick={() => {}}>
              <div className={current === value.title ? selected : unselected}>
                {value.icons}
                <p className="text-sm font-light">{value.title}</p>
              </div>
            </Link>
          ))}
        </aside>
      </div>

      <div className="flex flex-grow bg-zinc-50 flex-col">
        <div className="w-full h-[3.5rem] flex-shrink-0 border border-b-1 ">
          {" "}
          <TopBar toogle={setToogleMenu} toogleValue={toogleMenu} />
        </div>
        <div className="flex flex-grow  bg-white">
          <Outlet />
        </div>
      </div>
      <ToastContainer
        toastClassName="text-xs"
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        bounce
      />
    </div>
  );
};

export default Main;
