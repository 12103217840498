import {
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { MdClose, MdEdit, MdMoreVert } from "react-icons/md";
import Overview from './view_items/overview'
import Transactions from './view_items/transactions'

const ViewItem = ({ viewItem, item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState(0);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabs = [<Overview item={item}/>, <Transactions/>];
  return (
    <div className="w-full h-full flex flex-col bg-white">
      <div className="flex  w-full px-3 h-16 place-items-center place-content-between ">
        <div className="w-full">
          <p className="text-2xl capitalize">{item.name}</p>
        </div>
        <div className="flex gap-3">
          {item.active ? (
            <div className="px-3 bg-green-400 flex place-content-center place-items-center">
              <p className="text-sm text-white">ACTIVE</p>
            </div>
          ) : (
            <div className="px-3 bg-slate-400 flex place-content-center place-items-center">
              <p className="text-sm text-white">INACTIVE</p>
            </div>
          )}
          <div className="w-10 h-10 rounded-md border border-slate-400 flex place-content-center place-items-center cursor-pointer">
            <MdEdit />
          </div>
          <div
            className="h-10 w-10 rounded-md border border-slate-400 flex place-content-center place-items-center cursor-pointer"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MdMoreVert />
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>Delete</MenuItem>
            <MenuItem onClick={() => {
                item.active = !item.active
                handleClose();
            }}>Mark as Inactive</MenuItem>
          </Menu>
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                viewItem(null);
              }}
            >
              <MdClose size={18} />
            </IconButton>
          </Tooltip>
        </div>
        <div></div>
      </div>
      <p className="text-xs pl-5">{item.sku}</p>
      <Tabs value={value} onChange={handleChange} aria-label="dashboard tabs" className="px-5">
          <Tab
            label="Overview"
            sx={{ textTransform: "none", color: "black" }}
          />
          <Tab
            label="Transactions"
            sx={{ textTransform: "none", color: "black" }}
          />
         
          </Tabs>
      <hr/>
      <div
        className="w-full h-[75vh]  overflow-auto"
        style={{ scrollbarWidth: "thin" }}
      >
        {tabs[value]}
      </div>
    </div>
  );
};

export default ViewItem;
