import { Button, CircularProgress, IconButton } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { useStateContext } from "../utils/contextprovider";

const AddManufacturer = ({ closeMenu, reloadManu }) => {
  const [newManufacturer, setNewManufacturer] = useState(false);
  const [manufacturerName, setManufacturerName] = useState("");
  const [uploading, setUploading] = useState(false);
  const { user } = useStateContext();
  const apiUrl = process.env.REACT_APP_INVENTORY_API_URL;
  const apiKey = process.env.REACT_APP_INVENTORY_API_KEY;
  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#6E6658 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#2E4053",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };

  const saveManufacturer = () => {
    setUploading(true);

    axios
      .post(
        apiUrl + "items/save_manufacturer",
        {
          manufacturer: manufacturerName,
          org_id: user.org_id,
        },
        {
          headers: {
            "API-Key": apiKey,
          },
        }
      )
      .then((result) => {
        setUploading(false);
        setManufacturerName("")
        toast("Manufacturer saved");
        reloadManu()
      })
      .catch((error) => {
        setUploading(false);
        toast("Failed try again");
      });
  };

  return (
    <div className="w-full pb-10 bg-white rounded-b-lg flex flex-col">
      <div className="flex place-content-between place-items-center border-b h-14 p-5">
        <p className="text-sm">Manage Manufacturers</p>
        <IconButton
          onClick={() => {
            closeMenu(false);
          }}
        >
          <AiOutlineClose size={15} color="red" />
        </IconButton>
      </div>
      {!newManufacturer ? (
        <div className="flex place-items-center p-5 border border-slate-300 border-b">
          <Button
            sx={useStyle.Button}
            startIcon={<AiOutlinePlus size={15} />}
            onClick={() => {
              setNewManufacturer(true);
            }}
          >
            New Manufacturer
          </Button>
        </div>
      ) : (
        <div className="w-full p-5 flex place-content-center place-items-center">
          <div className="w-full h-40 bg-slate-50 border border-slate-300 flex flex-col place-content-center p-5 gap-3">
            <p className="text-sm text-red-500">Manufacturer Name*</p>
            <input
              className="border-[1px] bg-zinc-50 rounded-md border-slate-300 text-black w-full sm:w-2/3 p-2"
              type="text"
              id="org_id"
              value={manufacturerName}
              onChange={(e) => {
                setManufacturerName(e.target.value);
              }}
              placeholder=""
            />
            <div className="flex gap-2">
              <Button
                size="small"
                sx={useStyle.Button}
                onClick={() => {
                  if (manufacturerName !== "" || !uploading) {
                    saveManufacturer();
                  }
                }}
                endIcon={uploading ? <CircularProgress size={10} /> : <></>}
              >
                Save
              </Button>
              <Button
                size="small"
                sx={useStyle.OutlineButton}
                variant="outlined"
                onClick={() => {
                  setNewManufacturer(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        bounce
      />
    </div>
  );
};

export default AddManufacturer;
