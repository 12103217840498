import React, { useEffect, useRef, useState } from "react";
import Title from "../../../component/title";
import { BiUpload } from "react-icons/bi";
import { Button, CircularProgress } from "@mui/material";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useStateContext } from "../../../utils/contextprovider";
import Cookies from "js-cookie";
import app from "../../../utils/app";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";

const Branding = () => {
  const [img, setImg] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);
  const [company, setCompany] = useState(null);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [viewImage, setViewImage] = useState();
  const hiddenFileInput = useRef(null);
  const { user } = useStateContext();
  const apiUrl = process.env.REACT_APP_INVENTORY_API_URL;
  const apiKey = process.env.REACT_APP_INVENTORY_API_KEY;
  const token = Cookies.get("inventory_app_token");

  const params = {
    org_id: user.org_id,
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#6E6658 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#2E4053",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };

  const framerMenuPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = () => {
    axios
      .get(apiUrl + "organisation/index", {
        params,
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        setCompany(results.data.data);
        setLoadingCompany(false);
      })
      .catch((error) => {
        setLoadingCompany(false);
        setError(true);
      });
  };

  const uploadHandler = () => (event) => {
    var files = event.target.files;

    /// check if file size is above 1MB
    //console.log(files[0].size)
    if (files[0].size < 1e6) {
      var arr = [];

      for (let i = 0; i < files.length; i++) {
        const obj = URL.createObjectURL(files[i]);
        const file = files[i];
        arr.push({ name: files.item(i).name, item: obj, file: file });
      }
      event.target.value = null;

      //console.log(arr);

      setImg(arr[0]);
    } else {
      toast("File size is above 1MB");
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  const uploadImage = () => {
    const storage = getStorage(app);
    const ext = img.name.split(".")[1];
    const dt = Date.now();
    const storageRef = ref(storage, "branding/" + user.org_id + "/" + dt + ext);

    const uploadTask = uploadBytesResumable(storageRef, img.file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        setUploading(false);
        toast("Failed to upload file please try again");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateBrandingLogo(downloadURL);
          //console.log("File available at", downloadURL);
        });
      }
    );
  };

  /// change the download url for the company logo
  const updateBrandingLogo = (url) => {
    axios
      .post(
        apiUrl + "organisation/update_logo",
        {
          org_id: user.org_id,
          logo: url,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((results) => {
        setUploading(false);
        company["logo"] = url;
        toast("File uploaded");
      })
      .catch((error) => {
        console.log(error);
        setUploading(false);
        toast("Failed to upload file please try again");
      });
  };

  return (
    <div className="w-full h-full flex flex-col">
      <Title title={"Branding"} />
      {loadingCompany ? (
        <div className="w-full h-full flex place-items-center place-content-center">
          <CircularProgress size={15} />
        </div>
      ) : error ? (
        <div className="flex place-content-center place-items-center h-full w-full">
          <p
            className="text-xs cursor-pointer"
            onClick={() => {
              setError(false);
              getCompany();
            }}
          >
            Error loading tap to reload
          </p>
        </div>
      ) : (
        <div className="w-full sm:h-96 pb-5 border-b flex sm:flex-row flex-col place-items-center">
          <div className="sm:w-[30%] w-[90%] h-full flex flex-col gap-2 place-content-center place-items-center p-3">
            <p className="text-lg font-normal">Organization Logo</p>
            {img !== null ? (
              <div className="flex flex-col gap-2 w-full">
                <div className="w-full h-56 cursor-pointer flex flex-col place-content-center place-items-center border rounded-md">
                  <div
                    className="bg-center bg-contain bg-no-repeat w-full h-full"
                    style={{
                      backgroundImage: "url(" + img.item + ")",
                    }}
                  />
                </div>
                <div className="w-full flex gap-2 place-content-end">
                  <Button
                    sx={useStyle.Button}
                    onClick={() => {
                      setImg(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    endIcon={uploading ? <CircularProgress size={13} /> : <></>}
                    onClick={() => {
                      if (!uploading) {
                        if (img !== null) {
                          setUploading(true);
                          uploadImage();
                        } else {
                          toast("Please select file to upload");
                        }
                      }
                    }}
                    variant="outlined"
                    sx={useStyle.OutlineButton}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-1 w-full">
                <div
                  className="w-full h-56 cursor-pointer flex sm:flex-row flex-col place-content-center place-items-center rounded-md border border-dashed gap-3 border-slate-400"
                  onClick={handleClick}
                >
                  <BiUpload className="text-blue-500 text-lg" />
                  <p className="text-sm font-medium text-blue-500">
                    Upload Your Organisation Logo
                  </p>
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    ref={hiddenFileInput}
                    onChange={uploadHandler()}
                  />
                </div>

                {company.logo === "" ? (
                  <></>
                ) : (
                  <div
                    className="w-16 h-16 bg-center bg-cover rounded-md border border-slate-300"
                    style={{
                      backgroundImage: "url(" + company.logo + ")",
                    }}
                    onClick={() => {
                      setViewImage(company.logo);
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 p-3">
            <p>
              This logo will be displayed in transaction PDFs and email
              notifications.
            </p>
            <div className="flex flex-col gap-1">
              <p className="text-xs text-slate-400">
                Preferred Image Dimensions: 240 x 240 pixels @ 72 DPI
              </p>
              <p className="text-xs text-slate-400">Maximum File Size: 1MB</p>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        toastClassName="text-xs"
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        bounce
      />

      <AnimatePresence mode="wait" initial={false}>
        {viewImage && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
              onClick={() => {
                setViewImage(false);
              }}
            />
            <motion.div
              {...framerMenuPanel}
              className="absolute w-[40%] h-[80%] z-50"
              aria-label="Account"
            >
              <div className="w-full h-full bg-white rounded-md flex flex-col gap-2 p-3">
                <div className="w-full h-14 flex place-content-end">
                  <div
                    className="w-10 h-10 rounded-full cursor-pointer flex place-content-center place-items-center bg-black"
                    onClick={() => {
                      setViewImage(false);
                    }}
                  >
                    <AiOutlineClose color="white" size={13} />{" "}
                  </div>
                </div>
                <div
                  className="w-full h-full bg-center bg-contain bg-no-repeat flex place-items-center"
                  style={{
                    backgroundImage: "url(" + viewImage + ")",
                  }}
                ></div>
                <div className="h-10 w-full"></div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Branding;
