import Dashboard from "../containers/main/dashboard";
import Main from "../containers/main";
import Settings from "../containers/main/settings";
import ViewSuppliers from "../containers/main/suppliers/view";
import Reports from "../containers/main/reports";
import Items from "../containers/main/inventory/items";
import PriceLists from "../containers/main/inventory/price_lists";
import ItemGroups from "../containers/main/inventory/item_groups";
import InventoryAdjustments from "../containers/main/inventory/inventory_adjustments";
import Vendors from "../containers/main/purchases/vendors";
import PurchaseOrder from "../containers/main/purchases/purchase_order";
import PurchaseReceives from "../containers/main/purchases/purchase_receives";
import Bills from "../containers/main/purchases/bills";
import PaymentsMade from "../containers/main/purchases/payments_made";
import VendorCredits from "../containers/main/purchases/vendor_credits";
import Warehouse from "../containers/main/organisation/warehouse";
import Customers from "../containers/main/sales/customers";
import RetainerInvoices from "../containers/main/sales/retainer-invoices";
import SalesOrder from "../containers/main/sales/sales_order";
import Packages from "../containers/main/sales/packages";
import Shipments from "../containers/main/sales/shipments";
import Invoices from "../containers/main/sales/invoices";
import CreditNotes from "../containers/main/sales/credit_notes";
import PaymentsReceived from "../containers/main/sales/payments_received";
import SalesReturns from "../containers/main/sales/sales_returns";
import Branding from "../containers/main/organisation/branding";
import Currency from "../containers/main/organisation/currency";
import Profile from "../containers/main/organisation/profile";
import GuestLogin from "./guest_login";
const { Navigate, createBrowserRouter } = require("react-router-dom");
const { default: NotFound } = require("../containers/not_found");
const { default: Login } = require("../containers/login");

/// Navigation routes for the application
/// Guest login manages the login page
/// Main is the main navigation pages

const router = createBrowserRouter([
  /// 404 page return custom page on wildcard
  {
    path: "*",
    element: <NotFound />,
  },
  //Login routes
  {
    path: "/",
    element: <GuestLogin />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },

  //Main page links
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/inventory/items",
        element: <Items />,
      },
     
      {
        path: "/inventory/price-lists",
        element: <PriceLists />,
      },
      {
        path: "/inventory/item-groups",
        element: <ItemGroups />,
      },
      {
        path: "/inventory/inventory-adjustments",
        element: <InventoryAdjustments />,
      },
      {
        path: "/purchases/vendors",
        element: <Vendors />,
      },
      {
        path: "/purchases/purchase-order",
        element: <PurchaseOrder />,
      },
      {
        path: "/purchases/purchase-receives",
        element: <PurchaseReceives />,
      },
      {
        path: "/purchases/bills",
        element: <Bills />,
      },
      {
        path: "/purchases/payments-made",
        element: <PaymentsMade />,
      },
      {
        path: "/purchases/vendor-credits",
        element: <VendorCredits />,
      },
      {
        path: "/sales/customers",
        element: <Customers />,
      },
      {
        path: "/sales/retainer-invoices",
        element: <RetainerInvoices />,
      },
      {
        path: "/sales/sales-order",
        element: <SalesOrder />,
      },
      {
        path: "/sales/packages",
        element: <Packages />,
      },
      {
        path: "/sales/shipments",
        element: <Shipments />,
      },
      {
        path: "/sales/invoices",
        element: <Invoices />,
      },
      {
        path: "/sales/payments-received",
        element: <PaymentsReceived />,
      },
      {
        path: "/sales/sales-returns",
        element: <SalesReturns />,
      },
      {
        path: "/sales/credit-notes",
        element: <CreditNotes />,
      },
      {
        path: "/organisation/warehouse",
        element: <Warehouse />,
      },
      {
        path: "/organisation/profile",
        element: <Profile />,
      },
      {
        path: "/organisation/branding",
        element: <Branding />,
      },
      {
        path: "/organisation/currencies",
        element: <Currency />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/reports",
        element: <Reports />,
      },
      {
        path: "/suppliers/viewsuppliers",
        element: <ViewSuppliers />,
      },
    ],
  },
]);

export default router;
